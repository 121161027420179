import React, { useRef } from "react"

import styles from "./faq.module.css"

export default function Faq({ title, children, index }) {
  const ref = useRef()

  const toggleStateFaq = () => {
    const currentFaq = ref.current
    currentFaq.classList.toggle(styles.faqTitleActive)
    const panel = currentFaq.nextElementSibling
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px"
    }
  }

  return (
    <div className={styles.faq}>
      <button
        className={styles.faqTitle}
        onClick={toggleStateFaq}
        onKeyDown={toggleStateFaq}
        tabIndex={index}
        ref={ref}
      >
        {title}
      </button>
      <div className={styles.faqAnswer}>
        <p>{children}</p>
      </div>
    </div>
  )
}

import React from "react"

import styles from "./post-content.module.css"

export default function PostContent({ children, id, classVariant }) {
  return (
    <div
      id={id}
      className={`${styles.postContent} ${
        classVariant ? styles[classVariant] : ""
      }`}
    >
      {children}
    </div>
  )
}

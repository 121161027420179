import React from "react"
import { Trans } from "react-i18next"

import styles from "./hero-text.module.css"

export default function HeroText({
  children,
  title,
  text,
  textKey,
  subtitle,
  id,
  idTitle,
  classVariant,
}) {
  return (
    <div
      className={`${styles.heroText} ${
        classVariant ? styles[classVariant] : ""
      }`}
    >
      {children}
      <div id={id} className={styles.heroTextContent}>
        <h1 id={idTitle} className={styles.heroTextContentTitle}>
          {title}
          {subtitle && (
            <span>
              <br />
              {subtitle}
            </span>
          )}
        </h1>
        {text && textKey && (
          <p
            className={`${styles.heroTextContentText} ${styles.heroTextContentTextMobileHiddenBr}`}
          >
            <Trans i18nKey={textKey}>{text}</Trans>
          </p>
        )}
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react"
import { useTranslation, Trans } from "react-i18next"

import i18next from "../i18n/config"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import HeroText from "../components/hero-text/hero-text"
import Container from "../components/container/container"
import Header from "../components/header/header"
import Content from "../components/content/content"
import Footer from "../components/footer/footer"
import PostContent from "../components/post-content/post-content"
import Faq from "../components/faq/faq"

export default function FaqPage({ location }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.faq.title")
  const PageDescription = t("meta.description")

  const Questions = t("pages.faq.questions")

  const renderQuestions = []

  for (const [index, faq] of Questions.entries()) {
    const i18nKey = `pages.faq.questions.${index}.answer`
    renderQuestions.push(
      <Faq key={index} index={index} title={faq.title}>
        <Trans i18n={i18next} i18nKey={i18nKey}>
          {faq.answer}
          <a>.</a>
        </Trans>
      </Faq>
    )
  }

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <HeroText idTitle="faq-hero-title" title={t("pages.faq.hero.title")}>
        <Container>
          <Header classVariant="headerText" location={location} />
        </Container>
      </HeroText>
      <Content classVariant="contentPage">
        <Container>
          <PostContent id="faq-content">{renderQuestions}</PostContent>
        </Container>
      </Content>
      <Footer />
    </Wrapper>
  )
}
